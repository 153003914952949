import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore"
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import { collection, addDoc } from "firebase/firestore"; 
const firebaseConfig = {
  apiKey: "AIzaSyDDGj3tgV9GzN2S0E74XqZwAn7-0ITbgss",
  authDomain: "fitabbey.firebaseapp.com",
  projectId: "fitabbey",
  storageBucket: "fitabbey.appspot.com",
  messagingSenderId: "176916642755",
  appId: "1:176916642755:web:927512de12b16b15a65b76"
};


const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [email, setEmail] = useState("")
  const [terms, setTerms] = useState(false)
  const [message,setMessage] = useState("")
  const [showSnackbar,setShowSnackbar] = useState(false)
  useEffect(() => {
    Firebase.initializeApp(firebaseConfig);
  }, [])

  const writeUserData =async () => {
    
try {
  if(email!=""){
  const db = getFirestore();
  const docRef = await addDoc(collection(db, "users"), {
    email: email
  });
  setEmail("")
  setShowSnackbar(true)
  setTimeout(()=>{
    setShowSnackbar(false)
  },3000)
}
} catch (e) {
  console.error("Error adding document: ", e);
}
    
  };

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
  
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
       <Snackbar
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        open={showSnackbar}
        autoHideDuration={5000}
        message="Email registered successfully!"
        onClose={()=>{setShowSnackbar(false)}}
      />

      <div className="container">
      <div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:10,marginBottom:50,marginTop:40,textAlign:"center",fontSize:18}}>
  In this age of sedentary lifestyle, one of the most overlooked aspect of our everyday life is exercise and diet.<br></br> We are a small team of engineers, hardcore fitness enthusiasts and product enthusiasts from top B-schools, trying to solve the most common problems a person faces when he/she starts his/her fitness journey using Machine Learning Technology.
  </div>
        <div
          className={innerClasses}
        >
          
          <div className="cta-slogan">
            <h3 className="m-0">
             Join hands to make world a healthy place
              </h3>
          </div>
          <div className="cta-action" style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
            <Input value={email} onChange={(e)=>{setEmail(e.target.value)}} id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your best email">
            
            </Input>
           {terms && <div style={{marginLeft:10,cursor:"pointer",borderWidth:0.5,borderRadius:50+"%",borderColor:"#e5e5e5",borderStyle:"solid",padding:10}} onClick={()=>{writeUserData()}}>
              <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg" >
                <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#f44336"  />
              </svg>
              </div>}
          </div>
        </div>
        <div style={{marginTop:20,display:"flex",justifyContent:'center',alignItems:"flex-start",flexDirection:"row"}}>
        <input type={"checkbox"} checked={terms} onChange={()=>{setTerms(e=>{return !e})}} style={{marginTop:8}}></input> 
        <label style={{marginLeft:5,marginRight:20,marginBottom:50}}>I agree to receive your newsletter and accept the data privacy statement.</label>
        </div>
         
       
      </div>
     
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;